
          @import "src/styles/variables/fonts.scss"; 
          @import "src/styles/variables/breakpoints.scss"; 
          @import "src/styles/variables/mixins.scss";
        
html,
body,
#root,
.App,
main {
  height: 100%;
}

body {
  margin: 0;
  backdrop-filter: blur(5px);

  @include mobile {
    background-size: cover;
    backdrop-filter: none;
  }
}

input {
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
}
